import { SIGN_UP } from '../const/api.const';
import { POST_JSON_RESPONSE_DEFAULT_FORMAT } from '../const/requests.const';
import { getUserMetaDataFromStorage } from '../utils/analytics.utils';

type SubmitSignUpFormType = {
  onSuccess: () => void;
  onError: (msg?: string) => void;
  onFinally: () => void;
  onStart: () => void;
  body?: {
    email: string;
    password: string;
    telegram?: string;
  };
};
export const SubmitSignUpForm = ({
  onSuccess,
  onError,
  onFinally,
  onStart,
  body,
}: SubmitSignUpFormType) => {
  const metaData = getUserMetaDataFromStorage() || {};

  onStart();

  fetch(`${SIGN_UP}`, {
    ...POST_JSON_RESPONSE_DEFAULT_FORMAT,
    body: JSON.stringify({
      ...body,
      ...metaData,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        onSuccess();
      } else {
        onError(data.msg);
      }
    })
    .catch(() => onError())
    .finally(() => onFinally());
};
