import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const getUserSignUpSchema = (t: TFunction) =>
  Yup.object()
    .shape(
      {
        email: Yup.string()
          .email(t('error.form.invalid_email'))
          .required(t('error.form.required.email')),
        password: Yup.string()
          .min(8, t('error.form.is_short', { length: 8 }))
          .max(255, t('error.form.is_long', { length: 255 }))
          .required(t('error.form.required.password')),
        telegram: Yup.string()
          .notRequired()
          .when('telegram', {
            is: (value: string) => value?.length,
            then: (rule) =>
              rule.matches(
                /^(?=.{5,32}$)(?!.*__)(?!^(telegram|admin|support))[a-z][a-z0-9_]*[a-z0-9]$/gi,
                t('error.form.invalid_tg_username')
              ),
          }),
      },
      [['telegram', 'telegram']]
    )
    .required();
