// extracted by mini-css-extract-plugin
export var agreement = "SignUpFormHeader-module--agreement--z6RO8";
export var antBtnLg = "SignUpFormHeader-module--ant-btn-lg--qP5o+";
export var antBtnPrimary = "SignUpFormHeader-module--ant-btn-primary--NTLlD";
export var antFormItem = "SignUpFormHeader-module--ant-form-item--Zf4kk";
export var antFormItemLabel = "SignUpFormHeader-module--ant-form-item-label--6bUJX";
export var antInput = "SignUpFormHeader-module--ant-input--HeZ3j";
export var antMessageNotice = "SignUpFormHeader-module--ant-message-notice--XLiYo";
export var antMessageNoticeContent = "SignUpFormHeader-module--ant-message-notice-content--ae1DT";
export var errorMessage = "SignUpFormHeader-module--errorMessage--IHTnk";
export var title = "SignUpFormHeader-module--title--RPyJR";
export var wrapper = "SignUpFormHeader-module--wrapper--uYfiw";