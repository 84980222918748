import React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import { PRIVACY_POLICY_ROUTE, TERMS_ROUTE } from '../../const/routes.const';
import * as styles from './SignUpFormHeader.module.scss';

const SignUpFormHeader = (): JSX.Element => (
  <header className={styles.wrapper}>
    <h1 className={styles.title}>
      <Trans>Registration</Trans>
    </h1>
    <div className={styles.agreement}>
      <Trans i18nKey="register.agreement">
        <span>By register, you agree to our </span>
        <Link to={TERMS_ROUTE}>Terms of Service</Link>
        <span> and </span>
        <Link to={PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>
        <span>.</span>
      </Trans>
    </div>
  </header>
);

export default SignUpFormHeader;
