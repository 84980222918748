import React, { useEffect, useState } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Formik } from 'formik';
import { Typography } from 'antd';
import { Form, Input, SubmitButton } from 'formik-antd';
import { navigate } from 'gatsby';
import { REG_SUCCESS_ROUTE } from '../../const/routes.const';
import { UserSignUpData } from '../../types/signUp.schemas';
import { getUserSignUpSchema } from '../../schemas/user.schemas';
import { USER_EMAIL_STORAGE_NAME } from '../../const/storage.const';
import { useMessage } from '../../hooks/useMessage.hook';
import { SubmitSignUpForm } from '../../actions/signUp.actions';
import * as styles from './SignUpForm.module.scss';
import SignUpFormHeader from './SignUpFormHeader';
import SignUpFormFooter from './SignUpFormFooter';

const MESSAGE_ERROR_KEY = 'errorMessageKey';

const TelegramHint = () => {
  const i18n = useTranslation();

  return (
    <div>
      <Typography.Text>{i18n.t('Telegram')}</Typography.Text>{' '}
      <Typography.Text type="secondary">
        ({i18n.t('optional, for a quick connection with support')})
      </Typography.Text>
    </div>
  );
};

const SignUpForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { defaultLanguage, language } = useI18next();
  const { t } = useTranslation();
  const message = useMessage();
  const initialValues: UserSignUpData = {
    email: '',
    password: '',
    telegram: '',
  };
  const suffix = <span />;

  const error = (msg = 'Something went wrong. Try again later.') => {
    message
      .error({
        content: t(msg),
        duration: 5,
        className: 'errorMessage',
        key: MESSAGE_ERROR_KEY,
        onClick: () => message.destroy(MESSAGE_ERROR_KEY),
      })
      .then();
  };

  useEffect(
    () => () => {
      message.destroy(MESSAGE_ERROR_KEY);
    },
    []
  );

  const handleSubmit = (values: UserSignUpData) => {
    SubmitSignUpForm({
      onStart: () => {
        message.destroy(MESSAGE_ERROR_KEY);
        setLoading(true);
      },
      onSuccess: () => {
        localStorage.setItem(USER_EMAIL_STORAGE_NAME, values.email);
        navigate(
          `${
            defaultLanguage !== language ? `/${language}` : ''
          }${REG_SUCCESS_ROUTE}`
        );
      },
      onError: (msg?: string) => error(msg),
      onFinally: () => setLoading(false),
      body: {
        email: values.email,
        password: values.password,
        telegram: values.telegram,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <SignUpFormHeader />
        <Formik
          initialValues={initialValues}
          validationSchema={() => getUserSignUpSchema(t)}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form layout="vertical">
            <Form.Item label={t('Email')} name="email">
              <Input
                name="email"
                placeholder={t('you@example.com')}
                size="large"
                suffix={suffix}
              />
            </Form.Item>
            <Form.Item label={t('Password')} name="password">
              <Input.Password
                name="password"
                placeholder={t('Enter password')}
                size="large"
                suffix={suffix}
              />
            </Form.Item>
            <Form.Item label={<TelegramHint />} name="telegram">
              <Input
                name="telegram"
                placeholder={t('nickname')}
                size="large"
                suffix={suffix}
              />
            </Form.Item>
            <SubmitButton
              block
              disabled={false}
              loading={loading}
              size="large"
              type="primary"
            >
              {t('Create account')}
            </SubmitButton>
          </Form>
        </Formik>
        <SignUpFormFooter />
      </div>
    </div>
  );
};

export default SignUpForm;
