import React from 'react';
import { Trans, Link } from 'gatsby-plugin-react-i18next';
import * as styles from './SignUpFormFooter.module.scss';
import { DOWNLOADS_ROUTE } from '../../const/routes.const';

const SignUpFormFooter = () => {
  return (
    <footer className={styles.wrapper}>
      <Trans i18nKey={'register.has_account'}>
        <span>Already have an account? Log in with </span>
        <Link to={DOWNLOADS_ROUTE}>your client</Link>
        <span>.</span>
      </Trans>
    </footer>
  );
};

export default SignUpFormFooter;
