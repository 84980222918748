// extracted by mini-css-extract-plugin
export var agreement = "SignUpForm-module--agreement--U5--Q";
export var antBtnLg = "SignUpForm-module--ant-btn-lg--p1lP0";
export var antBtnPrimary = "SignUpForm-module--ant-btn-primary--5aW0-";
export var antFormItem = "SignUpForm-module--ant-form-item--EoiiF";
export var antFormItemLabel = "SignUpForm-module--ant-form-item-label--3YMUC";
export var antInput = "SignUpForm-module--ant-input--FU-Cb";
export var antMessageNotice = "SignUpForm-module--ant-message-notice--r3t9x";
export var antMessageNoticeContent = "SignUpForm-module--ant-message-notice-content--EutlX";
export var errorMessage = "SignUpForm-module--errorMessage--RWPcv";
export var form = "SignUpForm-module--form--SFpuH";
export var header = "SignUpForm-module--header--SgRb9";
export var title = "SignUpForm-module--title--9dnEq";
export var wrapper = "SignUpForm-module--wrapper--n1F4D";